<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <!-- SideBar For staff departments -->
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Department *"
                  invalid-feedback="Department is required."
                  ref="department"
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter department name"
                    v-validate="'required'"
                    name="classField"
                    ref="name"
                    v-model="myObj.name"
                  />
                </b-form-group>
              </b-col>

              <!-- <b-col md="12">
                <b-form-group label="Class name *">
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter class name"
                    v-validate="'required'"
                    name="classField"
                    ref="name"
                    v-model="myObj.name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Sections *"
                  invalid-feedback="Sections is required."
                  ref="sections"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    v-model="myObj.sections"
                    taggable
                    push-tags
                    placeholder="Add Sections"
                    label="title"
                  />
                </b-form-group>
              </b-col> -->

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row class="mt-1">
            <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
              <b-button
                @click="AddOpen()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Add</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              xl="10"
              lg="9"
              md="8"
              sm="12"
              cols="12"
            >
              <b-form-group class="">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filters.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="dataLoading"
            show-empty
            responsive
            hover
            :selectable="rights.edit"
            select-mode="single"
            @row-selected="Edit($event[0].id)"
          >
            <template #empty="scope">
              <h3 class="mt-1 mb-1" style="text-align: center">
                No records found
              </h3>
            </template>
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.name }}
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="text-center">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item.id)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>

                <b-button
                  v-if="rights.delete"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(this.right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    EditDep(row) {
      this.depObj.id = row.id;
      this.depObj.name = row.name;
      this.depObj.campusID = this.$store.state.userData.cId;
      this.visibility1 = true;
      this.sidebarTitle = "Edit Department";
      this.sidebarButton = "Update";
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },

    OpenDepartmentF() {
      this.visibility1 = true;
      this.depObj.id = 0;
      this.depObj.name = "";
      this.depObj.campusID = this.$store.state.userData.cId;
      this.sidebarTitle = "Add Department";
      this.sidebarButton = "Save";
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      this.visibility = true;
      this.sidebarTitle = "Edit Staff Department";
      this.sidebarButton = "Update";

      var elem = this.$refs["name"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add Staff Department";
      this.sidebarButton = "Save";

      var elem = this.$refs["name"];
      elem.state = undefined;
    },

    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepName() {
      var elem = this.$refs["depname"];
      if (this.depObj.name == "" || this.depObj.name == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepartment() {
      var elem = this.$refs["department"];
      if (this.myObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSections() {
      var elem = this.$refs["sections"];
      if (this.myObj.sections.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
    },
    hideDepSideBar() {
      this.visibility1 = false;
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },

    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.name.length > 2 ? true : false);
    },

    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.dataLoading = false;
      // console.log("staff dept", this.items);
    },
    async LoadDepartments() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.myDepartments = await this.get(obj);
    },

    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
    },

    async DepAdd() {
      this.CheckDepName();
      if (this.CheckDepName() == false) {
        return 0;
      } else {
        if (this.depObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "departments?db=" +
              this.$store.state.userData.db,
            body: this.depObj,
            message: "Department added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility1 = false;
          if (status) this.LoadDepartments();
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "departments/" +
              this.depObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.depObj,
            message: "Department updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility1 = false;
            this.LoadDepartments();
          }
        }
      }
    },

    async DeleteDep(id) {
      var status = await this.delete({
        url:
          this.$store.state.domain +
          "departments/" +
          id +
          "?db=" +
          this.$store.state.userData.db,
        body: null,
        message: "Department removed successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      if (status) this.LoadDepartments();
    },

    async Add() {
      this.CheckName();
      if (this.CheckName() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "StaffDepartments?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Department added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "StaffDepartments/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "department updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffDepartments/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Staff Department removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
